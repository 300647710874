import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_ERROR } from '../store/actions';
import { useCompanyService } from '../store/services/company.service';
import { useNavigate } from 'react-router-dom';

const Subscriptions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pricingTableId = 'prctbl_1PymWULyWHHHM0YWvQvzmPFL';
    const publishableKey =
        'pk_live_51N9dG0LyWHHHM0YWGBcff0NIIBfMhoS6OXpvYQWaUqjW4bg1g59aLzG9DYmNx7DabvfDBUBWp3NvCeOZ1Y8eL3wp00M7a4sLhe';
    const customerEmail = useSelector((state) => state.me.data.email);
    const companyid = useSelector((state) => state.company.data?.id);
    const { getUserAssociatedCompany } = useCompanyService();
    const { createStripeSession } = useCompanyService();

    const handleCreateStripeSession = async () => {
        const response = await createStripeSession(companyid);

        if (response.url) {
            window.location.href = response.url;
        }
    };

    const navigateToHome = () => {
        navigate('/home');
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup the script when the component unmounts
            document.body.removeChild(script);
        };
    }, []);
    useEffect(() => {
        if (!companyid) {
            getUserAssociatedCompany()
                //TODO: Multiple companies can be associated with user. But for MVP we will assume there is just one.
                .then((data) =>
                    dispatch(
                        UPDATE_COMPANY_SUCCESS({
                            data: {
                                id: data[0].id,
                                name: data[0].name,
                                timezone: {
                                    label: data[0].timezone,
                                    value: data[0].timezone,
                                },
                                industry: {
                                    label: data[0].industry_name,
                                    value: data[0].industry,
                                },
                                entity: {
                                    label: data[0].entity,
                                    value: data[0].entity,
                                },
                            },
                        }),
                    ),
                )
                .catch((err) => {
                    dispatch(UPDATE_COMPANY_ERROR({ err }));
                });
        }
    });

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <button
                    onClick={navigateToHome}
                    style={{
                        color: 'grey',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        marginBottom: '10px',
                        marginLeft: '30px',
                    }}
                >
                    Home Screen
                </button>
                <button
                    onClick={handleCreateStripeSession}
                    style={{
                        color: 'grey',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        fontWeight: 'bold', // Make the text bold
                        fontSize: '16px', // Increase font size
                        marginBottom: '30px', // Add margin to the right
                        marginLeft: '30px',
                    }}
                >
                    Manage Billing
                </button>
            </div>

            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                publishable-key={publishableKey}
                client-reference-id={companyid}
                customer-email={customerEmail}
            ></stripe-pricing-table>
        </div>
    );
};

export default Subscriptions;
