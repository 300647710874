import { Typography, useMediaQuery } from '@mui/material';
import { Drawer } from 'components/drawer';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    fetchBankAccountsFailure,
    fetchBankAccountsSuccess,
} from 'store/actions';
import {
    fetchBankReconciliations,
    getBankAccounts,
} from 'store/services/bank.Service';
import { useCompanyService } from 'store/services/company.service';
import MobileView from './Components/MobileViewReconcile';
import WebView from './Components/WebViewReconcile';

function AccountReconcile({ onSyncAll }) {
    const smDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { getUserAssociatedCompany } = useCompanyService();
    const { accounts } = useSelector((state) => state.bank);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.company.data);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [reconcileAccounts, setReconcileAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getBankReconciliations = useCallback(
        async (accountId) => {
            setIsLoading(true);
            const companyId = data?.id;
            const { token } = store.getState().auth;
            try {
                if (companyId && accountId) {
                    const response = await fetchBankReconciliations(
                        accountId,
                        token,
                    );
                    if (response?.length) {
                        setReconcileAccounts(response);
                    } else {
                        setReconcileAccounts([]);
                    }
                }
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        },
        [data?.id],
    );

    const handleAccount = useCallback(
        (account) => {
            if (account) {
                getBankReconciliations(account?.id);
            }
            setSelectedAccount(account);
        },
        [getBankReconciliations],
    );

    useEffect(() => {
        const fetchUserCompanyAndBankAccounts = async () => {
            const { token } = store.getState().auth;
            const companyId = data?.id;
            try {
                if (companyId) {
                    const bankAccountsData = await getBankAccounts(
                        companyId,
                        token,
                    );
                    dispatch(fetchBankAccountsSuccess(bankAccountsData));
                } else {
                    console.log('No associated company found.');
                }
            } catch (error) {
                dispatch(fetchBankAccountsFailure(error.message));
            }
        };

        fetchUserCompanyAndBankAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserAssociatedCompany, dispatch]);

    const reconcile = (row) => {
        onSyncAll('two', row);
    };

    return (
        <>
            <Typography ml={1.8} pb={2} sx={{ fontWeight: '800' }}>
                Select an account to reconcile
            </Typography>

            {!smDown ? (
                <WebView
                    onSyncAll={onSyncAll}
                    data={accounts}
                    setSelectedAccount={handleAccount}
                    reconcile={reconcile}
                />
            ) : (
                <MobileView
                    onSyncAll={onSyncAll}
                    data={accounts}
                    setSelectedAccount={handleAccount}
                    reconcile={reconcile}
                />
            )}
            <Drawer
                isDrawerOpen={!!selectedAccount}
                handleDrawer={handleAccount}
                accountName={selectedAccount?.name}
                title="Bank Reconciliation"
                isLoading={isLoading}
                accounts={reconcileAccounts}
                type="reconcile"
                handleClick={(data) =>
                    reconcile({
                        ...selectedAccount,
                        latest_reconciliation: { ...data },
                    })
                }
            />
        </>
    );
}

export default AccountReconcile;
